import React, { useEffect, useRef, useState } from "react";
import { IMAGES } from "../../utils/Images";
import Modal from "../../Components/Modal";
import Button from "../../Components/Button";
import {
  startTestData,
  startTestSections,
  startTestSectionsTwo,
} from "../../utils/DummyData";
import Inputfield from "../../Components/Inputfield";
import Case from "./Components/Case";
import NormalValuesTable from "./Components/NormalValuesTable";
import ImageModal from "./Components/ImageModal";
import MessageModal from "./Components/MessageModal";
import FinishModal from "./Components/FinishModal";
import CalculatorModal from "./Components/CalculatorModal";
import { useDispatch, useSelector } from "react-redux";
import { get, post, postWithSingleFile } from "../../api/axios.tsx";
import { setUserData } from "../../redux/slices/AuthSlice.js";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Components/toast.jsx";

const StartTest = ({ showModal, setShowModal }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showLeftCase, setShowLeftCase] = useState(true);
  const [showLeftNormalTable, setShowLeftNormalTable] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [tutorials, setTutorials] = useState([]);
  const [conductData, setConductData] = useState("");
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [showCalculatorModal, setShowCalculatorModal] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(60 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  const rightRef = useRef();
  const { user, casesForTest, questionLimit } = useSelector(
    (state) => state.Auth
  );
  const dispatch = useDispatch();
  const toggleImage = () => {
    setShowImageModal((prevStep) => !prevStep);
  };

  async function getUserData() {
    try {
      const response = await get({
        url: "/user/profile",
      });
      if (response?.data != null) {
        dispatch(setUserData(response?.data));
      }
    } catch (error) {
      console.log(error?.message);
    }
  }
  async function getConductData() {
    try {
      const response = await get({
        url: "/business-settings",
      });
      if (response?.data != null) {
        setConductData(response?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
  }
  async function getTutorialsData() {
    try {
      const response = await get({
        url: "/business-settings?type=tutorial",
      });
      if (response?.data != null) {
        setTutorials(response?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
  }

  useEffect(() => {
    getUserData();
    getConductData();
    getTutorialsData();

    if (!casesForTest?.length) {
      navigate(-1);
    }
  }, []);

  const toggleMessage = () => {
    setShowMessageModal((prevStep) => !prevStep);
  };

  const toggleFinish = () => {
    setShowLeftCase(false);
    setShowLeftNormalTable(false);
    setShowFinishModal((prevStep) => !prevStep);
  };

  const [selectedId, setSelectedId] = useState(
    startTestSections[0]?.id || null
  );

  const handleCaseClick = () => {
    setShowLeftCase(!showLeftCase);
    setShowLeftNormalTable(false); // Ensure only one panel is visible at a time
  };

  const handleNormalTableClick = () => {
    setShowLeftNormalTable(!showLeftNormalTable);
    setShowLeftCase(false); // Ensure only one panel is visible at a time
  };

  const toggleCalculator = () => {
    setShowCalculatorModal((prev) => !prev);
  };

  const startTestButtonData = [
    {
      onClick: handleCaseClick,
      image: IMAGES.CASE_STUDY,
      name: "Case",
    },
    {
      onClick: toggleImage,
      image: IMAGES.IMAGE,
      name: "Image",
    },
    {
      onClick: handleNormalTableClick,
      image: IMAGES.ICONS,
      name: "Table of Normal Values",
    },
    {
      onClick: toggleCalculator,
      image: IMAGES.CALCULATOR,
      name: "Calculator",
    },
  ];

  const startTestSectionsLength = Object.keys(startTestSections)?.length - 1;

  const closeModal = () => {
    setShowModal(false);
  };
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleModalNextBtn = () => {
    setSelectedId((prevId) => {
      const currentIndex = tutorials.findIndex((item) => item?.id === prevId);
      const nextIndex = (currentIndex + 1) % tutorials?.length;
      return tutorials[nextIndex].id;
    });
  };

  const handleModalBackBtn = () => {
    setSelectedId((prevId) => {
      const currentIndex = tutorials.findIndex((item) => item?.id === prevId);
      const prevIndex =
        (currentIndex - 1 + tutorials?.length) % tutorials?.length;
      return tutorials[prevIndex].id;
    });
  };
  const [testData, setTestData] = useState(null);
  const generateCasesData = () => {
    const casesData = {};
    for (let i = 0; i < casesForTest?.length; i++) {
      casesData[`cases[${i}]`] = `${casesForTest[i]}`; // Replace `Value${i}` with your actual value
    }
    return casesData;
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleNextStep = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      if (currentStep === 2 && !acceptTerms) {
        console.log("Accept This First");
        Toast({ message: "Accept the terms to proceed", isError: true });
        return;
      }
      if (currentStep === 2) {
        setSelectedId(tutorials?.[0]?.id);
      }
      if (currentStep === 3) {
        const casesData = generateCasesData();
        const response = await postWithSingleFile({
          url: "/start-test",
          data: {
            ...casesData,
            limit: questionLimit,
          },
        });

        if (response?.data != null) {
          setTestData(response?.data);
          setTimeRemaining(response?.data?.user_test?.time * 60);
          setSelectedId(response?.data?.questions?.[0]?.id);
          setCurrentStep((prevStep) => prevStep + 1);
        }
      }
      if (currentStep !== 3) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error?.message);
      setIsLoading(false);
    }
  };

  const handleClick = (id) => {
    setSelectedId(id);
  };
  const navigate = useNavigate();

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    } else {
      navigate(-1);
    }
  };
  const startTestSectionsTwoLength = testData?.questions?.length - 1;
  const initialItems = Array(10).fill("initialImage"); // Replace 'initialImage' with your actual initial item
  const [answerAttempt, setAnswerAttempt] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [filledAnswer, setFilledAnswer] = useState([]);
  const [startTestImageData, setStartTestImageData] = useState(initialItems);

  const odinalNumbers = [
    "1 st",
    "2 nd",
    "3 rd",
    "4 th",
    "5 th",
    "6 th",
    "7 th",
    "8 th",
    "9 th",
    "10 th",
    "11 th",
    "12 th",
    "13 th",
    "14 th",
    "15 th",
    "16 th",
    "17 th",
    "18 th",
    "19 th",
    "20 th",
    "21 st",
    "22 nd",
    "23 rd",
    "24 th",
    "25 th",
    "26 th",
    "27 th",
    "28 th",
    "29 th",
    "30 th",
    "31 st",
    "32 nd",
    "33 rd",
    "34 th",
    "35 th",
    "36 th",
    "37 th",
    "38 th",
    "39 th",
    "40 th",
    "41 st",
    "42 nd",
    "43 rd",
    "44 th",
    "45 th",
    "46 th",
    "47 th",
    "48 th",
    "49 th",
    "50 th",
  ];

  const selectedItemQues = testData?.questions?.filter(
    (item) => item?.id === selectedId
  )?.[0];

  const [inputValues, setInputValues] = useState([]);

  const handleInputChange = (index, value) => {
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
  };

  const generateAnswerData = () => {
    const answerData = {};
    for (let i = 0; i < inputValues?.length; i++) {
      answerData[`answers[${i}]`] = `${inputValues[i]}`;
    }
    return answerData;
  };

  const renderModalContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Modal
            showModal={showModal}
            closeModal={closeModal}
            childrenOne="Confirm Details"
            childrenThree={
              <div className="flex gap-2 items-center">
                <img
                  src={IMAGES.CLOCK}
                  alt="Big Logo"
                  className="  object-contain mx-auto w-[16px] h-[16px]  "
                />
                <p>{formatTime(timeRemaining)}</p>;
              </div>
            }
            childrenFour={
              <div className="flex-col items-center justify-center    w-full ">
                <div className="flex  justify-center items-center text-center  p-2 gap-2 mb-5">
                  <div>
                    <img
                      src={IMAGES.LOGO}
                      alt="Big Logo"
                      className="  object-contain mx-auto md:w-[100px] w-[80px] md:h-[120px] h-[80px] "
                    />
                  </div>
                  <div className="">
                    <p className=" lg:text-[36px] md:text-[28px] text-[22px] font-bold webName">
                      SAMPTRACK
                    </p>
                    <p className="lg:text-[18px] md:text-[16px] text-[12px]  font-light   text-black  ">
                      TRACK, TACKLE, TRIUMPH
                    </p>
                  </div>
                </div>

                {/* Box */}

                <div className="w-[90%] mx-auto bg-[#F9F9FB] border-[1px] border-[#707070] p-4 my-10 rounded-[4px]">
                  <div className="flex ">
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      First name:
                    </p>
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      {user?.first_name}
                    </p>
                  </div>
                  <div className="flex ">
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      Last name:
                    </p>
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      {user?.last_name}
                    </p>
                  </div>
                  <div className="flex ">
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      Test name:
                    </p>
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      {user?.test_name}
                    </p>
                  </div>
                  <div className="flex ">
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      Language:
                    </p>
                    <p className="font-medium md:text-[18px] text-[12px] text-[#000000] md:w-[20%] w-[50%]">
                      {user?.language}
                    </p>
                  </div>
                </div>

                {/* text */}
                <div>
                  <p className="text-[#737373] md:text-2xl text-lg text-light text-center mb-4">
                    Are the details above correct?
                  </p>
                </div>

                {/* button */}
                <div className="flex justify-center gap-10 w-[90%] mx-auto mb-10">
                  <Button
                    onPress={handleNextStep}
                    divstyle="md:w-[25%] w-[45%] md:mt-[60px] mt-[30px] rounded-lg text-white bg-[#060531] font-regular  lg:py-[3px] py-[5px]  lg:text-[23px] md:text-[18px] text-[16px]"
                    btnname="Confirm"
                  />
                  <Button
                    onPress={handlePreviousStep}
                    divstyle="md:w-[25%] w-[45%] md:mt-[60px] mt-[30px] rounded-lg text-white bg-[#E62801] font-regular  lg:py-[3px] py-[5px]lg:text-[23px] md:text-[18px] text-[16px]"
                    btnname="Cancel"
                  />
                </div>
              </div>
            }
          />
        );

      case 2:
        return (
          <Modal
            showModal={showModal}
            closeModal={closeModal}
            childrenOne="Confirm Details"
            childrenThree={
              <div className="flex gap-2 items-center">
                <img
                  src={IMAGES.CLOCK}
                  alt="Big Logo"
                  className="  object-contain mx-auto w-[16px] h-[16px]  "
                />
                <p>{formatTime(timeRemaining)}</p>;
              </div>
            }
            childrenFour={
              <div className="flex-col items-center justify-center    w-full ">
                <div className="flex  justify-center items-center text-center  p-2 gap-2 mb-5">
                  <div>
                    <img
                      src={IMAGES.LOGO}
                      alt="Big Logo"
                      className="  object-contain mx-auto md:w-[100px] w-[80px] md:h-[120px] h-[80px] "
                    />
                  </div>
                  <div className="">
                    <p className=" lg:text-[36px] md:text-[28px] text-[22px] font-bold webName">
                      SAMPTRACK
                    </p>
                    <p className="lg:text-[18px] md:text-[16px] text-[12px]  font-light   text-black  ">
                      TRACK, TACKLE, TRIUMPH
                    </p>
                  </div>
                </div>

                {/* Content */}
                <div className="mx-auto text-center   w-[90%] scroll-container h-[250px] rounded-[4px] border-[1px] border-[#707070] mb-4">
                  <div className="w-[90%] mx-auto my-3">
                    <h1 className="md:text-[16px] text-[13px] font-medium text-[#272727]  m-2">
                      {conductData?.[0]?.title ?? ""}
                    </h1>

                    <p className="md:text-[12px]  text-[9px] font-normal   text-black  ">
                      {conductData?.[0]?.value ?? ""}
                    </p>

                    <h1 className="md:text-[16px] text-[13px] font-medium text-[#272727] m-2">
                      {conductData?.[1]?.title ?? ""}
                    </h1>
                    <p className="md:text-[12px]  text-[9px] font-normal text-black">
                      {conductData?.[1]?.value ?? ""}
                    </p>
                  </div>
                </div>

                {/* text */}
                <div className="flex items-center focus:outline-none rounded-full justify-center">
                  <input
                    id="red-checkbox"
                    type="checkbox"
                    value={acceptTerms}
                    onChange={() => {
                      setAcceptTerms(!acceptTerms);
                    }}
                    className="w-4 h-4 focus:outline-none text-white bg-pink-600 border-[#A4A1A1] border-3"
                  />

                  <label className="ms-2 md:text-xl text-md font-normal text-[#A4A1A1]">
                    I accept these terms.
                  </label>
                </div>

                {/* button */}

                <div className="flex justify-center gap-10 w-[90%] mx-auto mb-10">
                  <Button
                    onPress={handleNextStep}
                    divstyle="md:w-[25%] w-[45%] md:mt-[60px] mt-[30px] rounded-lg text-white bg-[#060531] font-regular  lg:py-[3px] py-[5px]  lg:text-[23px] md:text-[18px] text-[16px]"
                    btnname="Confirm"
                  />
                  <Button
                    onPress={handlePreviousStep}
                    divstyle="md:w-[25%] w-[45%] md:mt-[60px] mt-[30px] rounded-lg text-white bg-[#E62801] font-regular  lg:py-[3px] py-[5px]lg:text-[23px] md:text-[18px] text-[16px]"
                    btnname="Cancel"
                  />
                </div>
              </div>
            }
          />
        );

      case 3:
        return (
          <Modal
            showModal={showModal}
            closeModal={closeModal}
            childrenOne={
              <p className="md:text-[14px] text-[8px] font-medium text-white ">
                Section: Introduction
              </p>
            }
            childrenTwo={
              <div className="flex items-center gap-3">
                <div>
                  <p className="md:text-[14px] text-[8px] text-white font-medium">
                    Intro Time
                  </p>
                  <p className="md:text-[14px] text-[8px] text-white font-medium">
                    Remaining:
                  </p>
                </div>
                <div className="flex  md:gap-2 gap-[3px] items-center">
                  <img
                    src={IMAGES.CLOCK}
                    alt="Big Logo"
                    className="  object-contain mx-auto md:w-[16px] md:h-[16px] w-[12px] h-[12px] "
                  />
                  <p className="md:text-[14px] text-[8px] text-white font-medium">
                    <p>{formatTime(timeRemaining)}</p>;
                  </p>
                </div>
              </div>
            }
            childrenThree={
              <div className="flex  gap-[2px] items-center">
                <p className="md:text-[14px] text-[8px] md:mr-1 mr-[1px] text-white font-medium">
                  {`Progress: ${percentage}%`}
                </p>

                {startTestImageData.map((i, index) => {
                  return (
                    <img
                      src={i.image}
                      alt="Big Logo"
                      className="  md:w-[8px] md:h-[11px]  w-[6px] h-[9px]"
                    />
                  );
                })}
              </div>
            }
            childrenFour={
              <div className="flex-col items-center justify-center   w-full ">
                <div className="bg-[#060531] w-full flex justify-between items-center px-2 py-1">
                  <p className="md:text-[14px] text-[8px]  text-white font-medium">
                    Test: {user?.test_name}
                  </p>
                  <p className="md:text-[14px] text-[8px]  text-white font-medium">
                    Candidate:
                    {` ${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
                  </p>
                </div>

                <div className="flex  w-[100%]">
                  <div className="flex md:w-[10%]  ">
                    <div className="flex flex-col p-[2px] py-4  md:w-[100%] ">
                      {tutorials?.map((item, index) => (
                        <div
                          key={item?.id}
                          className="mb-[2px] p-[1px] text-white flex items-center justify-center"
                          onClick={() => handleClick(item?.id)}
                        >
                          {selectedId === item?.id ? (
                            <div className="relative flex items-center justify-center md:w-16 w-12 h-full cursor-pointer">
                              <img
                                src={IMAGES.ARROW}
                                className="md:w-16 md:h-6 w-11 h-5"
                                alt="icon"
                              />
                              <p className="absolute top-0 left-0 right-0 bottom-0 py-1 flex items-center justify-center text-center md:text-[12px] text-[9px]">
                                {index + 1}
                              </p>
                            </div>
                          ) : (
                            <p className="bg-[#060531] w-[90%] text-center md:text-[12px] text-[9px] cursor-pointer">
                              {index + 1}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Modal for Content */}
                  <div className="flex-1 p-3">
                    {tutorials.map(
                      (item) =>
                        selectedId === item?.id && (
                          <div
                            key={item?.id}
                            className="h-[400px] bg-[#F9F9FB] shadow-md rounded-md p-3 border-[1px] border-[#707070]"
                          >
                            <p className="font-medium md:text-[16px] text-[12px] text-[#272727]">
                              {item?.title}
                            </p>
                            <p className="font-normal md:text-[12px] text-[8px] text-[#272727] mt-2">
                              {item?.value}
                            </p>
                            <p className="font-medium md:text-[16px] text-[12px] text-[#272727] mt-2">
                              Click on the "Next" button to continue
                            </p>
                          </div>
                        )
                    )}
                  </div>
                </div>

                {/* sections End */}

                {/* button */}
                <div className=" bg-[#4D4D4D] w-full flex justify-between items-center px-1  py-3">
                  <img
                    src={IMAGES.SETTINGS}
                    className="md:w-12 md:h-12 w-9 h-9 object-contain cursor-pointer"
                  />

                  <div className="flex justify-center items-center gap-3 w-[100%] ">
                    <Button
                      disabled={selectedId == "1" ? true : false}
                      onPress={handleModalBackBtn}
                      divstyle={`${
                        selectedId != "1" ? "" : "opacity-70  "
                      } md:px-8  px-3 rounded-lg text-white bg-[#060531] font-regular   lg:py-[3px] md:py-[2px] py-[5px]  lg:text-[18px] md:text-[15px] text-[14px]`}
                      btnname="Back"
                    />
                    <Button
                      disabled={
                        selectedId > startTestSectionsLength ? true : false
                      }
                      onPress={handleModalNextBtn}
                      divstyle={`${
                        selectedId > startTestSectionsLength ? "opacity-70" : ""
                      }
                      md:px-8  px-3 rounded-lg text-white bg-[#060531]  font-regular  lg:py-[3px] md:py-[2px] py-[5px]  lg:text-[18px] md:text-[15px] text-[14px]`}
                      btnname="Next"
                    />
                  </div>

                  <Button
                    onPress={handleNextStep}
                    divstyle="   md:px-2  px-[3px] md:w-[20%]  w-[40%] font-regular rounded-lg text-[#060531] bg-white font-regular   lg:py-[3px] py-[5px] lg:text-[18px] md:text-[15px] text-[14px]"
                    btnname="Start  Test"
                  />
                </div>
              </div>
            }
          />
        );

      case 4:
        return (
          <div
            className={`mx-auto grid  ${
              showLeftCase || showLeftNormalTable
                ? "lg:grid-cols-2 grid-cols-1 md:place-content-center   md:place-items-center md:justify-items-center  "
                : " "
            } place-items-center justify-items-center gap-2 `}
          >
            {/* Left Panel */}
            {showLeftCase == true ? (
              <Case
                showLeftCase={showLeftCase}
                caseDetail={selectedItemQues?.case_objective_data}
              />
            ) : showLeftNormalTable == true ? (
              <NormalValuesTable
                showLeftNormalTable={showLeftNormalTable}
                values={
                  selectedItemQues?.case_objective_data?.case_normal_values
                }
              />
            ) : null}

            {/* Right Panel */}
            <div ref={rightRef} className=" w-full   mx-auto  h-[700px]">
              <div className="bg-[#4D4D4D] text-white flex rounded-t-[4px] justify-between p-2 items-center">
                <div className="flex-col">
                  <p className="text-white md:text-[14px] text-[8px] xsm:text-[6px] font-medium">
                    Question: {testData?.user_test?.questions}
                  </p>
                  {/* <p className="text-white md:text-[14px] text-[8px] xsm:text-[6px] font-medium">
                    Section: 1
                  </p> */}
                </div>
                <div className="flex items-center">
                  <div>
                    <p className="md:text-[14px] text-[8px] xsm:text-[6px] text-white font-medium">
                      Section Time Remaining:
                    </p>
                    <div className="flex items-center gap-2">
                      <img
                        src={IMAGES.CLOCK}
                        alt="Clock"
                        className="object-contain md:w-[16px] md:h-[16px] w-[12px] h-[12px] xsm:w-[9px]  xsm:h-[9px] "
                      />
                      <p className="md:text-[14px] text-[8px] xsm:text-[6px]  text-white font-medium">
                        <p>{formatTime(timeRemaining)}</p>;
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-8">
                  <div className="gap-[2px] items-center">
                    <div className="flex gap-[2px] items-center">
                      {filledAnswer.map((i) => (
                        <div className="md:w-[8px] md:h-[11px] w-[6px] h-[8px] bg-[#060531]" />
                      ))}
                      {startTestImageData.map((i) => (
                        <div className="md:w-[8px] md:h-[11px] w-[6px] h-[8px] bg-white" />
                      ))}
                    </div>
                    <div>
                      <p className="md:text-[14px] text-[8px] xsm:text-[7px] mr-1 text-white font-medium">
                        {`Progress: ${percentage}%`}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={toggleFinish}
                    className="bg-white lg:px-6 md:px-4 px-2 rounded flex gap-2 items-center"
                  >
                    <p className="font-medium text-[#272727] md:text-[12px] text-[9px]">
                      Finish
                    </p>
                  </button>
                </div>
              </div>
              <div className="bg-[#060531] w-full flex justify-between items-center px-2 md:py-0 py-[3px]">
                <p className="md:text-[14px] text-[8px] text-white font-medium">
                  Test: {user?.test_name}
                </p>
                <p className="md:text-[14px] text-[8px]  text-white font-medium">
                  Candidate:
                  {` ${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
                </p>
              </div>

              <div className="items-center rounded-b-lg rounded-bl-lg   border-[1px] border-[#272727]">
                <div className="flex">
                  <div className="flex">
                    <div className="flex flex-col p-[2px] py-4  md:w-[80px] w-[50px]  ">
                      {testData?.questions?.map((item, index) => (
                        <div
                          key={item?.id}
                          className="mb-[2px] p-[1px] text-white flex items-center justify-center"
                          onClick={() => handleClick(item?.id)}
                        >
                          {selectedId === item?.id ? (
                            <div className="relative flex items-center justify-center w-16 h-full cursor-pointer">
                              <img
                                src={IMAGES.ARROW}
                                className="md:w-16 md:h-6 w-10 h-5"
                                alt="icon"
                              />
                              <p className="absolute top-0 left-0 right-0 bottom-0 md:py-1 pb-[1px] flex items-center justify-center text-center md:text-[12px] text-[10px]">
                                {index + 1}
                              </p>
                            </div>
                          ) : (
                            <p className="bg-[#060531] w-[90%] text-center md:text-[12px] text-[10px] cursor-pointer">
                              {index + 1}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex-1 p-3 ">
                    {testData?.questions?.map(
                      (item, index) =>
                        selectedId === item?.id && (
                          <div
                            key={item?.id}
                            className="   bg-white shadow-md  rounded-md md:p-3 p-2 border-[1px] border-[#707070]"
                          >
                            <p className="font-medium md:mb-2 mb-1 md:text-[16px] text-[10px] text-[#272727]">
                              {`Question ${index + 1}`}
                            </p>

                            <p className="font-normal md:my-2 my-1 md:text-[12px] text-[8px] text-[#272727]">
                              {item?.question}
                            </p>

                            {/* buttons */}
                            <div className="grid md:grid-cols-3 grid-cols-2  my-3  items-center md:gap-4 gap-2">
                              {startTestButtonData.map((item, ind) => {
                                return (
                                  <button
                                    onClick={item?.onClick}
                                    className=" rounded flex md:gap-2 gap-1 items-center"
                                  >
                                    <div className="bg-[#707070] md:size-7 size-5 rounded-sm flex items-center justify-center">
                                      <img
                                        src={item?.image}
                                        className="md:w-[18px] md:h-[18px] w-[12px] h-[12px] object-contain"
                                        alt="image"
                                      />
                                    </div>
                                    <p className="font-medium text-[#272727] text-start md:text-[12px] text-[8px]">
                                      {item?.name}
                                    </p>
                                  </button>
                                );
                              })}
                            </div>

                            {/* InputFields */}

                            <div className="flex flex-col md:gap-4 gap-1 md:h-[280px] h-[200px] md:px-2 px-2 md:my-5 md:pb-0 pb-3 scroll-container overflow-hidden">
                              {Array(
                                item?.list > 0
                                  ? item?.list
                                  : item?.state > 0
                                  ? item?.state
                                  : item?.single_answer
                              )
                                .fill(1)
                                .map((item, index) => (
                                  <Inputfield
                                    Labelname={`${odinalNumbers[index]}`}
                                    labelstyle="text-[#272727] md:text-base text-xs font-light"
                                    innerDiv="bg-white rounded-md w-full px-1  border-[1px] border-[#272727]"
                                    inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                    type="text"
                                    name={`${odinalNumbers[index]}`}
                                    value={inputValues[index]}
                                    onChange={(e) =>
                                      handleInputChange(index, e.target.value)
                                    }
                                  />
                                ))}

                              {/* {Array(
                                item?.list > 0
                                  ? item?.list
                                  : item?.state > 0
                                  ? item?.state
                                  : item?.single_answer
                              )
                                .fill(1)
                                .map((item, index) => (
                                (_, index) => (
                                  <Inputfield
                                    key={index}
                                    Labelname={`${odinalNumbers[index]}`}
                                    labelstyle="text-[#272727] md:text-base text-xs font-light"
                                    innerDiv="bg-white rounded-md w-full px-1 border-[1px] border-[#272727]"
                                    inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                    type="text"
                                    name={`${odinalNumbers[index]}`}
                                    value={inputValues[index]}
                                    onChange={(e) =>
                                      handleInputChange(index, e.target.value)
                                    }
                                  />
                                )
                              ))}  */}

                              {/* <Inputfield
                                Labelname="2 nd"
                                labelstyle="text-[#272727] md:text-base text-xs font-light"
                                innerDiv="bg-white rounded-md w-full px-1  border-[1px] border-[#272727]"
                                inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                type="text"
                                name="2 nd"
                              />
                              <Inputfield
                                Labelname="3 rd"
                                labelstyle="text-[#272727] md:text-base text-xs font-light"
                                innerDiv="bg-white rounded-md w-full px-1  border-[1px] border-[#272727]"
                                inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                type="text"
                                name="3 rd"
                              />
                              <Inputfield
                                Labelname="4 th"
                                labelstyle="text-[#272727] md:text-base text-xs font-light"
                                innerDiv="bg-white rounded-md w-full px-1  border-[1px] border-[#272727]"
                                inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                type="text"
                                name="4 th"
                              />
                              <Inputfield
                                Labelname="5 th"
                                labelstyle="text-[#272727] md:text-base text-xs font-light"
                                innerDiv="bg-white rounded-md w-full px-1  border-[1px] border-[#272727]"
                                inputStyle="w-full md:px-3 md:py-2 px-1 py-1"
                                type="text"
                                name="5 th"
                              /> */}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>

                <div className="bg-[#4D4D4D] w-full p-5 rounded-b-lg rounded-bl-sm">
                  <div className="flex justify-end md:gap-3 gap-2 w-[100%] mx-auto ">
                    <div
                      onClick={toggleMessage}
                      className="bg-white px-2 py-[6px] rounded-sm flex items-center justify-center rounded-[7px] cursor-pointer"
                    >
                      <img
                        src={IMAGES.MESSAGE}
                        className="md:w-[27px] md:h-[25px] w-[21px] h-[20px] object-contain"
                        alt="Arrow"
                      />
                    </div>

                    <Button
                      disabled={
                        selectedId === testData?.questions?.[0]?.id
                          ? true
                          : false
                      }
                      onPress={() => {
                        setSelectedId((prevId) => {
                          const currentIndex = testData?.questions?.findIndex(
                            (item) => item?.id === prevId
                          );
                          const prevIndex =
                            (currentIndex - 1 + testData?.questions?.length) %
                            testData?.questions?.length;
                          return testData?.questions?.[prevIndex]?.id;
                        });
                      }}
                      divstyle={`${
                        selectedId != "1" ? "" : "opacity-70  "
                      } md:px-8  px-4 rounded-lg text-white bg-[#060531] font-regular  lg:py-[3px] py-[2px]   lg:text-[18px] md:text-[15px] text-[14px]`}
                      btnname="Back"
                    />
                    <Button
                      disabled={
                        selectedId > startTestSectionsTwoLength ? true : false
                      }
                      onPress={async () => {
                        setSelectedId((prevId) => {
                          const currentIndex = testData?.questions?.findIndex(
                            (item) => item?.id === prevId
                          );
                          const nextIndex =
                            (currentIndex + 1) % testData?.questions?.length;

                          return testData?.questions?.[nextIndex]?.id;
                        });

                        try {
                          const answerData = generateAnswerData();
                          if (Object.keys(answerData).length !== 0) {
                            const response = await postWithSingleFile({
                              url: "/attempt-question",
                              data: {
                                user_test_id: testData?.user_test?.id,
                                question_id: selectedId,
                                comment: enteredText,
                                type: testData?.questions?.filter(
                                  (item) => item?.id === selectedId
                                )?.[0].type,
                                ...answerData,
                              },
                            });

                            if (response?.data != null) {
                              const currentFilledCount = answerAttempt;
                              const newFilledCount = currentFilledCount + 1;
                              const itemsToAdd = Math.floor(
                                (newFilledCount /
                                  testData?.user_test?.questions) *
                                  10
                              );
                              const percent = Math.floor(
                                (newFilledCount /
                                  testData?.user_test?.questions) *
                                  100
                              );
                              if (itemsToAdd <= 10) {
                                const filledAnswerSubmited =
                                  Array(itemsToAdd).fill("initialImage");
                                setFilledAnswer(filledAnswerSubmited);
                                setAnswerAttempt((prev) => prev + 1);
                                setPercentage(percent);
                                const numberOfItems = Math.max(
                                  0,
                                  10 - itemsToAdd
                                );
                                const RemoveAnswerSubmited =
                                  Array(numberOfItems).fill("initialImage");
                                setStartTestImageData(RemoveAnswerSubmited);
                              }
                            }
                            console.log(response);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                        setEnteredText("");
                        setInputValues([]);
                      }}
                      divstyle={`${
                        selectedId > startTestSectionsTwoLength
                          ? "opacity-70"
                          : ""
                      }
                      md:px-8  px-4  rounded-lg text-white bg-[#060531]  font-regular  lg:py-[3px] py-[2px]  lg:text-[18px] md:text-[15px] text-[14px]`}
                      btnname="Next"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Try */}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="w-[100%] flex justify-center md:h-screen">
        <div className={`w-full  grid p-2 `}>{renderModalContent()}</div>
        <ImageModal
          image={selectedItemQues?.image}
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
        />
        <CalculatorModal
          showCalculatorModal={showCalculatorModal}
          setShowCalculatorModal={setShowCalculatorModal}
        />

        <MessageModal
          enteredText={enteredText}
          setEnteredText={setEnteredText}
          showMessageModal={showMessageModal}
          setShowMessageModal={setShowMessageModal}
        />

        <FinishModal
          id={testData?.user_test?.id}
          showFinishModal={showFinishModal}
          setShowFinishModal={setShowFinishModal}
        />
      </div>
    </>
  );
};

export default StartTest;
