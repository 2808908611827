const generateImagePath = (name) => `/assets/${name}.png`;

const imageNames = [
  "logo",
  "auth_user",
  "auth_lock",
  "auth_messages",
  "auth_key",
  "auth_close",
  "auth_test",
  "auth_language",
  "eye",
  "secure",
  "graph",
  "clock",
  "box",
  "settings",
  "perfomance",
  "arrow",
  "image_close",
  "message",
  "book",
  "calculator",
  "case_study",
  "down_arrow",
  "hide",
  "icons",
  "image",
  "left_arrow",
  "left_arrow_navigate",
  "minus",
  "plus",
  "right_arrow",
  "right_arrow_navigate",
  "surface",
  "stop_watch",
  "checked",
  "danger",
  "calculator_modal",
  "auth_background",
  "hidden",
  "checked_chart",
  "graph_chart",
  "video",
];

const IMAGES = imageNames.reduce((acc, name) => {
  acc[name.toUpperCase()] = generateImagePath(name);
  return acc;
}, {});
console.log("IMAGES",IMAGES);


export { IMAGES };
