// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter,
//   Routes,
//   Route,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Index from "./Screens/Auth";
// import Home from "./Screens/Home";
// import Performance from "./Screens/Performance";
// import Previous from "./Screens/Previous";
// import Test from "./Screens/Modals/Test";
// import AuthWrapper from "./Screens/Auth/AuthWrapper/Index";
// import { PrimeReactProvider } from "primereact/api";
// import "primereact/resources/themes/saga-blue/theme.css";
// import "primereact/resources/primereact.min.css";
// import Splash from "./Screens/Splash";
// import { GetUser, LoginUser } from "./redux/slices/AuthSlice";

// function App() {
//   const [isLoading, setIsLoading] = useState(true);
//   const { token } = useSelector((state) => state.Auth);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       dispatch(GetUser());
//       setIsLoading(false);
//     }, 2000); // Adjust the timeout as needed

//     return () => clearTimeout(timer);
//   }, [dispatch]);

//   // useEffect(() => {
//   //   // Simulate loading/auth check
//   //   const timer = setTimeout(() => {
//   //     const storedToken = localStorage.getItem("token");
//   //     if (storedToken) {
//   //       dispatch(LoginUser({})); // Restore the token to Redux
//   //     }
//   //     setIsLoading(false);
//   //   }, 2000); // Adjust the timeout as needed

//   //   return () => clearTimeout(timer);
//   // }, [dispatch]);

//   if (isLoading) {
//     return <Splash />;
//   }

//   return (
//     <div className="app">
//       <PrimeReactProvider>
//         <Routes>
//           <Route
//             path="/login"
//             element={!token ? <Index /> : <Navigate to={"/"} />}
//           />
//           <Route
//             path="/forgotPassword"
//             element={!token ? <AuthWrapper /> : <Navigate to={"/"} />}
//           />
//           <Route
//             path="/"
//             element={token ? <Home /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/previous"
//             element={token ? <Previous /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/performance"
//             element={token ? <Performance /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/test"
//             element={token ? <Test /> : <Navigate to="/login" />}
//           />
//         </Routes>
//       </PrimeReactProvider>
//     </div>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "./Screens/Auth";
import Home from "./Screens/Home";
import Performance from "./Screens/Performance";
import Previous from "./Screens/Previous";
import Test from "./Screens/Modals/Test";
import AuthWrapper from "./Screens/Auth/AuthWrapper/Index";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Splash from "./Screens/Splash";
import { GetUser } from "./redux/slices/AuthSlice";
import { Toaster } from "react-hot-toast";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(GetUser());
      setIsLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, [dispatch]);

  if (isLoading) {
    return <Splash />;
  }

  return (
    <div className="app">
      <PrimeReactProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={!token ? <Index /> : <Navigate to={"/"} />}
            />
            <Route
              path="/forgotPassword"
              element={!token ? <AuthWrapper /> : <Navigate to={"/"} />}
            />
            <Route
              path="/"
              element={token ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/previous"
              element={token ? <Previous /> : <Navigate to="/login" />}
            />
            <Route
              path="/performance"
              element={token ? <Performance /> : <Navigate to="/login" />}
            />
            <Route
              path="/test"
              element={token ? <Test /> : <Navigate to="/login" />}
            />
          </Routes>
        </BrowserRouter>
        <Toaster />
      </PrimeReactProvider>
    </div>
  );
}

export default App;
