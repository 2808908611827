import React, { useState } from "react";
import Inputfield from "../../../Components/Inputfield.jsx";
import PasswordInput from "../../../Components/PasswordInput.jsx";
import Button from "../../../Components/Button.jsx";
import { IMAGES } from "../../../utils/Images.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../../redux/slices/AuthSlice.js";
import { useFormik } from "formik";
import { Loginvalidationschema } from "../../../utils/schema.js";
import { post } from "../../../api/axios.tsx";
import { Toast } from "../../../Components/toast.jsx";

const Login = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await post({
        url: "/login",
        includeToken: false,
        data: values,
      });

      if (response?.data != null) {
        Toast({ message: response?.messages?.[0] });
        dispatch(LoginUser(response?.data));
        navigate("/");
      }
    } catch (error) {
      Toast({ message: error?.message, isError: true });

      console.log(error?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Loginvalidationschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <form className="w-full" onSubmit={formik.handleSubmit}>
        <Inputfield
          innerDiv="mt-[30px]  bg-white rounded-full w-full px-5 bg-transparent"
          inputStyle="w-full px-3 py-3  "
          type="email"
          placeholder="Enter your Email"
          name="email"
          htmlFor="email"
          id="email"
          leadingImage={IMAGES.AUTH_USER}
          imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
          onChange={formik.handleChange}
          values={formik.values.email}
          errors={formik.errors.email}
          touched={formik.touched.email}
        />

        <PasswordInput
          innerdiv="mt-[30px]  bg-white rounded-full w-full px-5 bg-transparent"
          inputStyle="w-full px-3 py-3  "
          name="password"
          name="password"
          htmlFor="password"
          id="password"
          placeholder="Enter Password"
          leadingImage={IMAGES.AUTH_LOCK}
          imageStyle="w-[30px] h-[30px] -ml-3 mr-2"
          onChange={formik.handleChange}
          values={formik.values.password}
          errors={formik.errors.password}
          touched={formik.touched.password}
        />

        <div className="flex justify-between lg:mt-[41px] mt-[20px] focus:outline-none items-center">
          <div className="flex items-center focus:outline-none rounded-full">
            <label class="flex items-center cursor-pointer ">
              <input
                type="checkbox"
                class="hidden peer"
                value={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <div
                class=" md:w-[30px] md:h-[30px] w-[15px]  h-[15px] rounded-full  focus:outline-none
             flex items-center justify-center focus:outline-none"
              >
                {checked ? (
                  <img
                    src={IMAGES.CHECKED}
                    className="md:w-[30px] md:h-[30px] w-[15px]  h-[15px] "
                  />
                ) : (
                  <div className="  rounded-full bg-white md:w-[30px] md:h-[30px] w-[15px]  h-[15px]"></div>
                )}
              </div>
              <span class="ms-2 text-sm font-normal text-white">Remember</span>
            </label>
          </div>

          <div className="cursor-pointer">
            <a
              onClick={() => navigate("/forgotPassword")}
              className="text-white font-normal text-sm"
            >
              Forgot password?
            </a>
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            divstyle="w-[50%] md:mt-[60px] mt-[30px] rounded-full text-[#060531] bg-white font-medium lg:py-3 py-2 lg:text-[20px]  md:text-[16px]  text-[14px]"
            btnname="Login"
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default Login;
